.line-data {
  background-color: black;
  min-height: 100vh;
  position: relative;
}

.main-content {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
}

/* Busbar styling */
.busbar {
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 4px;
  background-color: red;
  margin: 100px 0px 0 20px;
}

.connection-point {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Dot styling */
.dot {
  position: absolute;
  top: -10px;
}

/* Vertical line from busbar */
.vertical-line {
  position: absolute;
  top: 15px;
  width: 2px;
  height: 30px;
  background-color: red;
}

.busbar00::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 54px;
  height: 100%;
  background-color: black;
  transform: translateX(-50%);
}

.busbar00 {
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 4px;
  background-color: red;
  transform: translate(53px, 397px);
  /* margin: 100px 0px 0 20px; */
}

.break-busbar {
  background-color: transparent !important;
  /* Creates a break */
  width: 10px;
  /* Adjust the gap size */
}

/* Cross symbol */
.cross {
  position: absolute;
  top: 35px;
}

.crossdefault {
  position: absolute;
  top: -15px;
  left: 76px;
  z-index: 1;
}

/* Diagonal line */
.diagonal-line {
  position: absolute;
  top: 70px;
  left: -18px;
  width: 2px;
  height: 50px;
  background-color: #FFBF00;
  transform: rotate(-45deg);
}
.diagonal-line-green {
  position: absolute;
  top: 70px;
  left: -18px;
  width: 2px;
  height: 50px;
  background-color: green;
  transform: rotate(-45deg);
}

.diagonal-line-green-remove {
  position: absolute;
  top: 70px;
  left: -18px;
  width: 2px;
  height: 50px;
  background-color: transparent;
  transform: rotate(-45deg);
}


/* Horizontal line from busbar */
.horizontal-line {
  position: absolute;
  top: 24px;
  right: 10px;
  width: 2px;
  height: 35px !important;
  background-color: red !important;
  transform: rotate(90deg);
}

/* Vertical line to meter */
.vertical-line-to-meter {
  position: absolute;
  top: 109px;
  width: 2px;
  height: 250px;
  background-color: red;
  left: -1px;
}

/* Right arrow */
.right-arrow {
  position: absolute;
  right: 10px;
  top: 40px;
  color: white;
  font-size: 20px;
}

/* Meters container */
.meters-container {
  display: flex;
  justify-content: space-between;
  /* margin: 120px 40px 0 20px; */
}

/* Individual meter unit */
.meter-unit {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
  position: absolute;
  top: 110px;
  right: 5px;
}

.meter-unitData {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  width: 240px;
  position: absolute;
  gap: 22px;
  flex-direction: row-reverse;
  top: -230px;
  right: 8px;
  width: max-content;
}

/* Monitor icon box */
.monitor-icon {
  font-size: 40px;
  color: white;
  margin-bottom: 10px;
}

/* Meter information */
.meter-label {
  color: #7efb98;
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 14px;
  text-align: center;
}

.meter-values {
  color: #ffff00;
  font-size: 12px;
  height: 189px;
  overflow: auto;
  padding-right: 13px;
}

.meter-values p {
  margin: 2px 0;
}

.meter-values span {
  color: #40e0d0;
}

.left-arrow {
  position: absolute;
  left: 10px;
  top: 40px;
  color: white;
  font-size: 20px;
}

.busbar2 {
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 4px;
  background-color: red;
  transform: translate(53px, 397px);
  /* margin: 100px 0px 0 20px; */
}

.busbar1 {
  display: flex;
  justify-content: space-between;
  position: relative;
  /* height: 4px; */
  background-color: red;
  transform: translate(53px, 11px);
  /* margin: 100px 0px 0 20px; */
  left: 216px;
  width: 1274px;
}

.diagonal-line1 {
  position: absolute;
  top: -69px;
  left: -18px;
  width: 2px;
  height: 50px;
  background-color: #FFBF00;
  transform: rotate(-45deg);
}

.diagonal-line2 {
  position: absolute;
  top: -78px;
  left: -2px;
  width: 2px;
  height: 50px;
  background-color: #FFBF00;
  transform: rotate(-2deg);
}

.default-line-on {
  position: absolute;
  top: -20px;
  left: 60px;
  width: 4px;
  height: 45px;
  background-color: red;
  transform: rotate(91deg);
  z-index: 999;
}

.default-line {
  position: absolute;
  top: -42px;
  left: 56px;
  width: 4px;
  height: 50px;
  background-color: #FFBF00;
  transform: rotate(42deg);
}
.default-line-green {
  position: absolute;
  top: -42px;
  left: 56px;
  width: 4px;
  height: 50px;
  background-color: green;
  transform: rotate(42deg);
}
.default-line-off {
  position: absolute;
  top: -42px;
  left: 56px;
  width: 4px;
  height: 50px;
  background-color: red;
  transform: rotate(42deg);
}

.diagonal-line-on {
  position: absolute;
  top: -73px;
  left: -1px;
  width: 2px;
  height: 46px;
  background-color: red;
  transform: rotate(0deg);
}

.diagonal-line-off-upward {
  position: absolute;
  top: 55px;
  left: -1px;
  width: 2px;
  height: 54px;
  background-color: red;
  transform: rotate(0deg);
}

.diagonal-line-on-upward {
  position: absolute;
  top: 70px;
  left: -16px;
  width: 2px;
  height: 50px;
  background-color: green;
  transform: rotate(-45deg);
}

.diagonal-line-off {
  position: absolute;
  top: -69px;
  left: -18px;
  width: 2px;
  height: 50px;
  background-color: green;
  transform: rotate(-45deg);
}

@media (max-width: 1536px) {
  .line-data {
    min-height: 125vh;
  }

  /* .busbar1 {
    display: flex;
    justify-content: space-between;
    position: relative;
    background-color: red;
    transform: translate(53px, 11px);
    left: 155px;
    width: 895px;
  }
  .crossdefault {
    position: absolute;
    top: 51%;
    left: 56%;
    transform: translateX(-50%);
    z-index: 1;
  }
  .default-line {
    position: absolute;
    top: 47%;
    left: 53%;
    width: 2px;
    height: 50px;
    background-color: #FFBF00;
    transform: rotate(42deg);
  }
  .default-line-off {
    position: absolute;
    top: 47%;
    left: 53%;
    width: 4px;
    height: 50px;
    background-color: red;
    transform: rotate(42deg);
  }*/
}

@media(min-width:1300px) and (max-width:1498px){
  .default-line-off {
    position: absolute;
    top: -42px;
    left: 61px;
    width: 4px;
    height: 50px;
    background-color: red;
    transform: rotate(42deg);
}
.crossdefault {
  position: absolute;
  top: -13px;
  left: 82px;
  z-index: 1;
}
.default-line {
  position: absolute;
  top: -42px;
  left: 61px;
  width: 4px;
  height: 50px;
  background-color: #FFBF00;
  transform: rotate(42deg);
}
.default-line-green {
  position: absolute;
  top: -42px;
  left: 61px;
  width: 4px;
  height: 50px;
  background-color: green;
  transform: rotate(42deg);
}
.default-line-on {
  position: absolute;
  top: -20px;
  left: 68px;
  width: 4px;
  height: 45px;
  background-color: red;
  transform: rotate(91deg);
  z-index: 999;
}
}
@media(min-width:1499px) and (max-width:1598px){
  .default-line-off {
    position: absolute;
    top: -42px;
    left: 71px;
    width: 4px;
    height: 50px;
    background-color: red;
    transform: rotate(42deg);
}
.crossdefault {
  position: absolute;
  top: -13px;
  left: 96px;
  z-index: 1;
}
.default-line {
  position: absolute;
  top: -42px;
  left: 71px;
  width: 4px;
  height: 50px;
  background-color: #FFBF00;
  transform: rotate(42deg);
}
.default-line-green {
  position: absolute;
  top: -42px;
  left: 71px;
  width: 4px;
  height: 50px;
  background-color: green;
  transform: rotate(42deg);
}
.default-line-on {
  position: absolute;
  top: -20px;
  left: 81px;
  width: 4px;
  height: 45px;
  background-color: red;
  transform: rotate(91deg);
  z-index: 999;
}
}
@media(min-width:1599px) and (max-width:1698px){
  .default-line-off {
    position: absolute;
    top: -42px;
    left: 76px;
    width: 4px;
    height: 50px;
    background-color: red;
    transform: rotate(42deg);
}
.crossdefault {
  position: absolute;
  top: -13px;
  left: 100px;
  z-index: 1;
}
.default-line {
  position: absolute;
  top: -42px;
  left: 76px;
  width: 4px;
  height: 50px;
  background-color: #FFBF00;
  transform: rotate(42deg);
}
.default-line-green {
  position: absolute;
  top: -42px;
  left: 76px;
  width: 4px;
  height: 50px;
  background-color: green;
  transform: rotate(42deg);
}
.default-line-on {
  position: absolute;
  top: -20px;
  left: 87px;
  width: 4px;
  height: 45px;
  background-color: red;
  transform: rotate(91deg);
  z-index: 999;
}
}
@media(min-width:1900px) and (max-width:2500px){
  .default-line-off {
    position: absolute;
    top: -42px;
    left: 94px;
    width: 4px;
    height: 50px;
    background-color: red;
    transform: rotate(42deg);
}
.crossdefault {
  position: absolute;
  top: -13px;
  left: 114px;
  z-index: 1;
}
.default-line {
  position: absolute;
  top: -42px;
  left: 94px;
  width: 4px;
  height: 50px;
  background-color: #FFBF00;
  transform: rotate(42deg);
}
.default-line-green {
  position: absolute;
  top: -42px;
  left: 94px;
  width: 4px;
  height: 50px;
  background-color: green;
  transform: rotate(42deg);
}
.default-line-on {
  position: absolute;
  top: -20px;
  left: 100px;
  width: 4px;
  height: 44px;
  background-color: red;
  transform: rotate(90deg);
  z-index: 999;
}
}
@media(min-width:1700px) and (max-width:1800px){
  .default-line-off {
    position: absolute;
    top: -42px;
    left: 84px;
    width: 4px;
    height: 50px;
    background-color: red;
    transform: rotate(42deg);
}
.crossdefault {
  position: absolute;
  top: -13px;
  left: 112px;
  z-index: 1;
}
.default-line {
  position: absolute;
  top: -42px;
  left: 84px;
  width: 4px;
  height: 50px;
  background-color: #FFBF00;
  transform: rotate(42deg);
}
.default-line-green {
  position: absolute;
  top: -42px;
  left: 84px;
  width: 4px;
  height: 50px;
  background-color: green;
  transform: rotate(42deg);
}
.default-line-on {
  position: absolute;
  top: -23px;
  left: 93px;
  width: 4px;
  height: 50px;
  background-color: red;
  transform: rotate(90deg);
}
}
@media(min-width:1800px) and (max-width:1899px){
  .default-line-off {
    position: absolute;
    top: -42px;
    left: 84px;
    width: 4px;
    height: 50px;
    background-color: red;
    transform: rotate(42deg);
}
.crossdefault {
  position: absolute;
  top: -13px;
  left: 112px;
  z-index: 1;
}
.default-line {
  position: absolute;
  top: -42px;
  left: 84px;
  width: 4px;
  height: 50px;
  background-color: #FFBF00;
  transform: rotate(42deg);
}
.default-line-green {
  position: absolute;
  top: -42px;
  left: 84px;
  width: 4px;
  height: 50px;
  background-color: green;
  transform: rotate(42deg);
}
.default-line-on {
  position: absolute;
  top: -23px;
  left: 96px;
  width: 4px;
  height: 50px;
  background-color: red;
  transform: rotate(90deg);
}
}